import React from 'react'
import css from './Contact.module.css'

const Contact = () => {
  return (
    <div className={css.contact}>
        <div className="container">
            <div className={css.formcontainer}>
                <form>
                    <div>
                        <label>Name</label>
                        <input type="text" placeholder='Enter your name'/>
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" placeholder='Enter your email'/>
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea name="message"  rows="10" placeholder='Message'></textarea>
                    </div>
                    <button>Submit</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Contact
