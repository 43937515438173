import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import Recovery from '../components/Recovery/Recovery'

const RecoveryPage = () => {
  return (
    <div>
      <Navbar/>
      <Recovery/>
      <Footer/>
    </div>
  )
}

export default RecoveryPage
