import React from 'react'
import css from './Recovery.module.css'

const Recovery = () => {
  return (
    <div className={css.recovery}>
        <div className="container">
            <div className={css.content}>
                <h2><span>Data</span> Recovery</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, illo. Quisquam, ipsam sunt repudiandae vero ullam consequatur commodi aliquam rem dolorem quidem! Amet atque, fugiat dicta deleniti officia quod quibusdam!</p>
                <button>Learn More</button>
            </div>
            
        </div>
    </div>
  )
}

export default Recovery
