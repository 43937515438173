import React, { useState } from 'react'
import './Navbar.css'
import { SiDatabricks } from 'react-icons/si'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes} from 'react-icons/fa'

const Navbar = () => {

    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    
  return (
    <div className='navbar'>
        
            <div className='logo'>
                <SiDatabricks className='icon'/>
                <h1>Secured</h1>
            </div>

            <ul className={ click ? "nav-menu active" : 'nav-menu'}>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/recovery'>Recovery</Link></li>
                <li><Link to='/cloud'>Cloud</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
                <button>Sign in</button>
            </ul>
            <div className='hamburger' onClick={handleClick}>
                {click? <FaTimes className='bars'/> : <FaBars className='bars'/>}
            
        </div>
    </div>
  )
}

export default Navbar