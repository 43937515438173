import React from 'react'
import css from './Cloud.module.css'

const Cloud = () => {
  return (
    <div className={css.cloud}>
        <div className="container">
            <div className={css.content}>
                <h2><span>Cloud</span> Security</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus at veritatis quisquam voluptatem nostrum reprehenderit, tempore sint placeat, aperiam alias, facilis voluptate quod itaque deleniti molestiae non odit esse necessitatibus.</p>
                <div><button>Sign Up</button></div>
            </div>
        </div>
    </div>
  )
}

export default Cloud
