import React from 'react'
import Cloud from '../components/Cloud/Cloud'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

const CloudPage = () => {
  return (
    <div>
      <Navbar/>
      <Cloud/>
      <Footer/>
    </div>
  )
}

export default CloudPage
