import React from 'react'
import css from './Hero.module.css'

const Hero = () => {
  return (
    <div className={css.hero}>
        <div className="container">
        <div className={css.content}>
            <h1>Data</h1>
            <h1 className={css.blue}>Secured</h1>
            <h1>Protection</h1>
            <div><button>Learn More</button></div>
            </div>
        </div>
    </div>
  )
}

export default Hero