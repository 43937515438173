import React from 'react'
import css from './Data.module.css'

const Data = () => {
  return (
    <div className={css.data}>
        <div className="container">
            <div className={css.content}>
                <h2><span>Data</span> Recovery</h2>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi officiis unde perferendis voluptas laborum aliquam culpa officia a maiores porro. Consequuntur officia corporis aliquam numquam, fuga quisquam illo. Obcaecati, voluptatum.</p>
                <button>Sign Up</button>
            </div>
            
        </div>
    </div>
  )
}

export default Data
