import React from 'react'
import css from './Footer.module.css'
import { SiDatabricks } from 'react-icons/si'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import { FiMail, FiFacebook, FiGithub, FiInstagram, FiLinkedin, FiDribbble } from 'react-icons/fi'
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <div className={css.footer}>
        <div className={css.container}>
            <div className={css.firstsection}>
            <div className={css.logo}>
                <SiDatabricks/>
                <h1>Secured.</h1>
                
            </div>
            <div className={css.arrow}>
            <Link activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <BsFillArrowUpCircleFill className='icon' />
                    </Link>
                </div>
            </div>
            <div className={css.bottomtext}>
                    <div className={css.col}>
                    <h3>Navigation</h3>
                    <p>Home</p>
                    <p>Data</p>
                    <p>Cloud</p>
                    <p>Contact</p>
                    </div>
                    <div className={css.col}>
                    <h3>My Account</h3>
                    <p>Login</p>
                    <p>My Data</p>
                    <p>Cloud Security</p>
                    <p>Important</p>
                    <p>Specialist</p>
                    </div>
                    <div className={css.col}>
                    <h3>Information</h3>
                    <p>Membership</p>
                    <p>Data Protocols</p>
                    <p>Cloud Protocols</p>
                    <p>Security Roles</p>
                    </div>
                    <div className={css.col}>
                    <h3>Legal</h3>
                    <p>Policies</p>
                    <p>Terms & Conditions</p>
                    <p>Securities</p>
                    </div>
                    <form>
                    <div className={css.newsletter}>
                        <h3>Join our team</h3>
                        <input type="text" placeholder='Enter your email' />
                        <FiMail className={css.mail} />
                        <div className={css.social}>
                            <FiInstagram className={css.socialIcon} />
                            <FiFacebook className={css.socialIcon} />
                            <FiLinkedin className={css.socialIcon} />
                            <FiDribbble className={css.socialIcon} />
                            <FiGithub className={css.socialIcon} />
                        </div>
                    </div>
                    </form>
                </div>

        </div>
    </div>
  )
}

export default Footer


